import { render, staticRenderFns } from "./component.vue?vue&type=template&id=1ef4d983&scoped=true"
import script from "./logic.ts?vue&type=script&lang=ts&external"
export * from "./logic.ts?vue&type=script&lang=ts&external"
import style0 from "./skin.styl?vue&type=style&index=0&id=1ef4d983&prod&lang=stylus&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ef4d983",
  null
  
)

export default component.exports
import {
    ENUM_BASE_ACCESS_LEVEL,
    ENUM_PROFILE_TYPE,
} from "./enums";

import APIClass from "../APIClass";
import {METHOD} from "../enums";
import {
    Users_Creation_request_POST_APIInterface,
    Users_Creation_request_code_POST_APIInterface,
    Users_Creation_request_POST_APIQueryInterface,
    Users_Creation_request_POST_APISendEmpty,
    Cart_Item_code_DELETE_APIInterface,
    Cart_Item_code_DELETE_APIQueryInterface,
    Documents_Document_code_Sds2excel_GET_APIInterface,
    Documents_Document_code_Sds2excel_GET_APIQueryInterface,
    Users_Login_MySDS_Documents_Suppliers_GET_APIInterface,
    Users_Login_MySDS_Documents_Suppliers_GET_APIQueryInterface,
    Documents_GET_APIInterface,
    Documents_GET_APIQueryInterface,
    Document_Details_GET_APIInterface,
    Document_Details_GET_APIQueryInterface,
    Documents_Verify_customer_code_GET_APIInterface,
    Documents_Verify_customer_code_GET_APIQueryInterface,
    Users_Login_Profile_type_Documents_Document_code_DELETE_APIInterface,
    Users_Login_Profile_type_Documents_Document_code_DELETE_APIQueryInterface,
    Users_Login_Profile_type_Documents_Document_code_PUT_APIInterface,
    Users_Login_Profile_type_Documents_Document_code_PUT_APIQueryInterface,
    Users_Login_Profile_type_Documents_Document_code_SelectInfReg_PUT_APIQueryInterface,
    Users_Login_Profile_type_Documents_Document_code_GET_APIInterface,
    Users_Login_Profile_type_Documents_Document_code_GET_APIQueryInterface,
    Contact_technical_support_POST_APIInterface,
    Contact_technical_support_POST_APIQueryInterface,
    Cart_GET_APIInterface,
    Cart_GET_APIQueryInterface,
    Cart_DELETE_APIInterface,
    Cart_DELETE_APIQueryInterface,
    Cart_PUT_APIInterface,
    Cart_PUT_APIQueryInterface,
    Cart_POST_APIInterface,
    Cart_POST_APIQueryInterface,
    Users_Login_Profile_type_Customer_codes_POST_APIInterface,
    Users_Login_Profile_type_Customer_codes_POST_APIQueryInterface,
    Users_Login_Profile_type_Customer_codes_DELETE_APIInterface,
    Users_Login_Profile_type_Customer_codes_DELETE_APIQueryInterface,
    Users_Login_Profile_type_Customer_codes_GET_APIInterface,
    Users_Login_Profile_type_Customer_codes_GET_APIQueryInterface,
    Signatures_Safebox_POST_APIInterface,
    Signatures_Safebox_POST_APIQueryInterface,
    Users_Login_MySDS_Documents_Changelog_GET_APIInterface,
    Users_Login_MySDS_Documents_Changelog_GET_APIQueryInterface,
    Contact_us_POST_APIInterface,
    Contact_us_POST_APIQueryInterface,
    Contact_us_POST_APISendEmpty,
    Documents_Document_code_Vigi_substances_GET_APIInterface,
    Documents_Document_code_Vigi_substances_GET_APIQueryInterface,
    Users_Login_MySDS_Documents_Quick_icpe_GET_APIInterface,
    Users_Login_MySDS_Documents_Quick_icpe_GET_APIQueryInterface,
    Users_Login_MySDS_Documents_Sds2excel_GET_APIInterface,
    Users_Login_MySDS_Documents_Sds2excel_GET_APIQueryInterface,
    Contacts_POST_APIInterface,
    Contacts_POST_APIQueryInterface,
    Users_Login_PUT_APIInterface,
    Users_Login_PUT_APIQueryInterface,
    Users_Login_PUT_APISendEmpty,
    Users_Login_Modify_email_request_POST_APIInterface,
    Users_Login_Modify_email_request_POST_APIQueryInterface,
    Users_Login_Email_PUT_APIInterface,
    Users_Login_Email_PUT_APIQueryInterface,
    Documents_Document_code_Quick_adr_GET_APIInterface,
    Documents_Document_code_Quick_adr_GET_APIQueryInterface,
    Documents_Document_code_Quick_icpe_GET_APIInterface,
    Documents_Document_code_Quick_icpe_GET_APIQueryInterface,
    Documents_Document_code_GET_APIInterface,
    Documents_Document_code_GET_APIQueryInterface,
    Documents_Document_code_Qrcode_GET_APIInterface,
    Documents_Document_code_Qrcode_GET_APIQueryInterface,
    Users_POST_APIInterface,
    Users_POST_APIQueryInterface,
    Search_steps_GET_APIInterface,
    Search_steps_GET_APIQueryInterface,
    Session_DELETE_APIInterface,
    Session_DELETE_APIQueryInterface,
    Session_POST_APIInterface,
    Session_POST_APIQueryInterface,
    Session_GET_APIInterface,
    Session_GET_APIQueryInterface,
    Session_PUT_APIInterface,
    Session_PUT_APIQueryInterface,
    Session_PUT_APISendEmpty,
    Users_Login_MySDS_Documents_Vigi_substances_GET_APIInterface,
    Users_Login_MySDS_Documents_Vigi_substances_GET_APIQueryInterface,
    Users_Login_MySDS_Documents_Quick_adr_GET_APIInterface,
    Users_Login_MySDS_Documents_Quick_adr_GET_APIQueryInterface,
    Users_Login_MySDS_Documents_GET_APIQueryInterface,
    Users_Login_MySDS_Documents_Export_GET_APIInterface,
    Users_Login_Profile_type_Documents_POST_APIInterface,
    Users_Login_Profile_type_Documents_POST_APIQueryInterface,
    Users_Login_Profile_type_Documents_DELETE_APIInterface,
    Users_Login_Profile_type_Documents_DELETE_APIQueryInterface,
    Users_Login_Profile_type_Documents_GET_APIInterface,
    Users_Login_Profile_type_Documents_GET_APIQueryInterface,
    Users_Login_Profile_type_PUT_APIInterface,
    Users_Login_Profile_type_PUT_APIQueryInterface,
    Users_Modify_password_request_POST_APIInterface,
    Users_Modify_password_request_POST_APIQueryInterface,
    Users_Login_Profiles_POST_APIInterface,
    Users_Login_Profiles_POST_APIQueryInterface,
    Users_Login_Admin_GET_APIInterface,
    Users_Login_Admin_GET_APIQueryInterface,
    Users_Login_Roles_GET_APIInterface,
    Users_Login_Roles_GET_APIQueryInterface,
    Cart_Checkout_POST_APIInterface,
    Cart_Checkout_POST_APIQueryInterface,
    Users_Login_Profile_type_History_GET_APIInterface,
    Users_Login_Profile_type_History_GET_APIQueryInterface,
    Suppliers_Supplier_code_Products_Product_code_GET_APIInterface,
    Suppliers_Supplier_code_Products_Product_code_GET_APIQueryInterface,
    Catalog_GET_APIInterface,
    Catalog_GET_APIQueryInterface,
    Open_Fds_GET_APIInterface,
    Open_Fds_GET_APIQueryInterface,
    Users_Admin_GET_APIQueryInterface,
    List_criterias_GET_APIInterface,
    List_criterias_GET_APIQueryInterface,
    Users_Login_Profile_type_Import_consultation_POST_APIQueryInterface,
    Report_Data_GET_APIQueryInterface,
    Report_Vigi_GET_APIQueryInterface,
    Users_Subscription_GET_APIInterface,
    Users_Subscription_GET_APIQueryInterface,
    Users_Login_Command_DELETE_APIQueryInterface,
    Users_Login_Command_POST_APIQueryInterface,
    Users_Login_Command_GET_APIQueryInterface,
    Request_customer_code_POST_APIInterface,
    Request_customer_code_POST_APIQueryInterface,
    Documents_Document_code_Preview_GET_APIInterface,
    Documents_Document_code_Preview_GET_APIQueryInterface,
    Request_customer_code_POST_APISendEmpty
} from "./types";

export class Users_Creation_request_POST_APIClass extends APIClass {

    params?:Users_Creation_request_POST_APIInterface;
    query?:Users_Creation_request_POST_APIQueryInterface;
    method:METHOD = METHOD.POST;
    url:string = "/users/creation_request";

    constructor(query?:Users_Creation_request_POST_APIQueryInterface, params?:Users_Creation_request_POST_APIInterface, sendEmpty?:Users_Creation_request_POST_APISendEmpty) {
        super(query, params, sendEmpty);
        if(!params) {
            this.params = {
                    profile_type : ENUM_PROFILE_TYPE.MYSDS,
                    first_name : "",
                    last_name : "",
                    email : "",
                    company_name : "",
            }
        }
    }

     get rules() {
         return {
            profile_type : {"type":"string","enum":["mySDS","admin","assistant"],"required":true},
            first_name : {"pattern":"^[^0-9,;:!?]+$","maxLength":"100","type":"string","required":true},
            last_name : {"pattern":"^[^0-9,;:!?]+$","maxLength":"100","type":"string","required":true},
            email : {"format":"email","type":"string","required":true},
            company_name : {"maxLength":"100","type":"string","required":true},
            country_code : {"pattern":"^[a-zA-Z][a-zA-Z]$","type":"string","required":false},
            address1 : {"maxLength":"100","type":"string","required":false},
            address2 : {"maxLength":"100","type":"string","required":false},
            address3 : {"maxLength":"100","type":"string","required":false},
            zipcode : {"maxLength":"100","type":"string","required":false},
            city : {"maxLength":"100","type":"string","required":false},
            state : {"maxLength":"100","type":"string","required":false},
            duns : {"pattern":"^[0-9]{9}$","type":"string","required":false},
            siret : {"pattern":"^[0-9]{14}$","type":"string","required":false},
            want_safebox : {"required":false},
            safebox : {"required":false},
            qsds_consent : {"type":"boolean","required":false},
            weka_consent : {"type":"boolean","required":false},
        }
    }
}
export class Users_Creation_request_code_POST_APIClass extends APIClass {

    params?:Users_Creation_request_code_POST_APIInterface;
    query?:Users_Creation_request_POST_APIQueryInterface;
    method:METHOD = METHOD.POST;
    url:string = "/users/creation_code";

    constructor(query?:Users_Creation_request_code_POST_APIInterface, params?:Users_Creation_request_POST_APIInterface) {
        super(query, params);
        if(!params) {
            this.params = {
                password: "",
                company_name: "",
                first_name : "",
                last_name : "",
                email : "",
            }
        }
    }

    get rules() {
        return {
            password : {"maxLength":"200","format":"password","minLength":"8","type":"string","required":true},
            first_name : {"pattern":"^[^0-9,;:!?]+$","maxLength":"100","type":"string","required":true},
            last_name : {"pattern":"^[^0-9,;:!?]+$","maxLength":"100","type":"string","required":true},
            email : {"format":"email","type":"string","required":true},
            company_name : {"maxLength":"100","type":"string","required":true},
            qsds_consent : {"type":"boolean","required":false},
            weka_consent : {"type":"boolean","required":false},
        }
    }
}

export class Cart_Item_code_DELETE_APIClass extends APIClass {

    params?:Cart_Item_code_DELETE_APIInterface;
    query?:Cart_Item_code_DELETE_APIQueryInterface;
    method:METHOD = METHOD.DELETE;
    url:string = "/cart/{item_code}";

    constructor(query?:Cart_Item_code_DELETE_APIQueryInterface, params?:Cart_Item_code_DELETE_APIInterface) {
        super(query, params);
        if(!query) {
            this.query = {
                item_code : "",
            }
        }
    }

     get rules() {
         return {
            item_code : {"maxLength":"100","pattern":"^[0-9A-Za-z-]+$","type":"string","required":true},
        }
    }
}

export class Documents_Document_code_Sds2excel_GET_APIClass extends APIClass {

    params?:Documents_Document_code_Sds2excel_GET_APIInterface;
    query?:Documents_Document_code_Sds2excel_GET_APIQueryInterface;
    method:METHOD = METHOD.GET;
    url:string = "/documents/{document_code}/sds2excel";

    constructor(query?:Documents_Document_code_Sds2excel_GET_APIQueryInterface, params?:Documents_Document_code_Sds2excel_GET_APIInterface) {
        super(query, params);
        if(!query) {
            this.query = {
                document_code : "",
            }
        }
    }

     get rules() {
         return {
            document_code : {"type":"string","pattern":"^[0-9]{7}-[0-9]{5}-[0-9]{5}-[0-9]{6}$","required":true},
        }
    }
}

export class Users_Login_MySDS_Documents_Suppliers_GET_APIClass extends APIClass {

    params?:Users_Login_MySDS_Documents_Suppliers_GET_APIInterface;
    query?:Users_Login_MySDS_Documents_Suppliers_GET_APIQueryInterface;
    method:METHOD = METHOD.GET;
    url:string = "/users/{login}/mySDS/documents/suppliers";

    constructor(query?:Users_Login_MySDS_Documents_Suppliers_GET_APIQueryInterface, params?:Users_Login_MySDS_Documents_Suppliers_GET_APIInterface) {
        super(query, params);
        if(!query) {
            this.query = {
                login : "",
            }
        }
    }

     get rules() {
         return {
            login : {"maxLength":"100","type":"string","required":true},
            limit : {"required":false},
            offset : {"required":false},
        }
    }
}

export class Documents_GET_APIClass extends APIClass {

    params?:Documents_GET_APIInterface;
    query?:Documents_GET_APIQueryInterface;
    method:METHOD = METHOD.GET;
    url:string = "/documents";

    constructor(query?:Documents_GET_APIQueryInterface, params?:Documents_GET_APIInterface) {
        super(query, params);
        if(!params) {
            this.params = {
                supplier_name : "",
            }
        }
    }

     get rules() {
         return {
            supplier_name : {"maxLength":"100","type":"string","required":true},
            search_value : {"maxLength":"100","type":"string","required":false},
            product_name : {"maxLength":"100","type":"string","required":false},
            product_code : {"maxLength":"100","type":"string","required":false},
            cpro : {"maxLength":"100","type":"string","required":false},
            family : {"maxLength":"100","type":"string","required":false},
            brand_name : {"maxLength":"100","type":"string","required":false},
            product_type : {"maxLength":"100","type":"string","required":false},
            ean : {"maxLength":"100","type":"string","required":false},
            customer_code : {"maxLength":"240","type":"string","required":false},
            limit : {"required":false},
            offset : {"required":false},
            supplier : {"maxLength":"100","type":"string","required":false},
        }
    }
}
export class Document_Details_GET_APIClass extends APIClass {

    params?:Document_Details_GET_APIInterface;
    query?:Document_Details_GET_APIQueryInterface;
    method:METHOD = METHOD.GET;
    url:string = "/landing_page";

    constructor(query?:Document_Details_GET_APIQueryInterface, params?:Document_Details_GET_APIInterface) {
        super(query, params);
        if(!params) {
            this.params = {
                document_code : "",
            }
        }
    }
    get rules() {
        return {
            document_code : {"maxLength":"100","type":"string","required":true},
        }
    }
}
export class Documents_Document_code_Preview_GET_APIClass extends APIClass {

    params?:Documents_Document_code_Preview_GET_APIInterface;
    query?:Documents_Document_code_Preview_GET_APIQueryInterface;
    method:METHOD = METHOD.GET;
    url:string = "/documents/{document_code}/preview";

    constructor(query?:Documents_Document_code_Preview_GET_APIQueryInterface, params?:Documents_Document_code_Preview_GET_APIInterface) {
        super(query, params);
        if(!params) {
            this.params = {
                document_code : "",
            }
        }
    }
    get rules() {
        return {
            document_code : {"maxLength":"100","type":"string","required":true},
        }
    }
}

export class Documents_Verify_customer_code_GET_APIClass extends APIClass {

    params?:Documents_Verify_customer_code_GET_APIInterface;
    query?:Documents_Verify_customer_code_GET_APIQueryInterface;
    method:METHOD = METHOD.GET;
    url:string = "/documents/verify_customer_code";

    constructor(query?:Documents_Verify_customer_code_GET_APIQueryInterface, params?:Documents_Verify_customer_code_GET_APIInterface) {
        super(query, params);
        if(!params) {
            this.params = {
                supplier_code : "",
                customer_code : "",
            }
        }
    }

     get rules() {
         return {
            supplier_code : {"maxLength":"100","type":"string","required":true},
            customer_code : {"maxLength":"240","type":"string","required":true},
        }
    }
}

export class Users_Login_Profile_type_Documents_Document_code_DELETE_APIClass extends APIClass {

    params?:Users_Login_Profile_type_Documents_Document_code_DELETE_APIInterface;
    query?:Users_Login_Profile_type_Documents_Document_code_DELETE_APIQueryInterface;
    method:METHOD = METHOD.DELETE;
    url:string = "/users/{login}/{profile_type}/documents/{document_code}";

    constructor(query?:Users_Login_Profile_type_Documents_Document_code_DELETE_APIQueryInterface, params?:Users_Login_Profile_type_Documents_Document_code_DELETE_APIInterface) {
        super(query, params);
        if(!query) {
            this.query = {
                login : "",
                profile_type : ENUM_PROFILE_TYPE.MYSDS,
                document_code : "",
            }
        }
    }

     get rules() {
         return {
            login : {"maxLength":"100","type":"string","required":true},
            profile_type : {"type":"string","enum":["mySDS","admin","assistant"],"required":true},
            document_code : {"type":"string","pattern":"^[0-9]{7}-[0-9]{5}-[0-9]{5}-[0-9]{6}$","required":true},
        }
    }
}

export class Users_Login_Profile_type_Documents_Document_code_PUT_APIClass extends APIClass {

    params?:Users_Login_Profile_type_Documents_Document_code_PUT_APIInterface;
    query?:Users_Login_Profile_type_Documents_Document_code_PUT_APIQueryInterface;
    method:METHOD = METHOD.PUT;
    url:string = "/users/{login}/{profile_type}/documents/{document_code}";

    constructor(query?:Users_Login_Profile_type_Documents_Document_code_PUT_APIQueryInterface, params?:Users_Login_Profile_type_Documents_Document_code_PUT_APIInterface) {
        super(query, params);
        if(!query) {
            this.query = {
                login : "",
                profile_type : ENUM_PROFILE_TYPE.MYSDS,
                document_code : "",
            }
        }
    }

     get rules() {
         return {
            login : {"maxLength":"100","type":"string","required":true},
            profile_type : {"type":"string","enum":["mySDS","admin","assistant"],"required":true},
            document_code : {"type":"string","pattern":"^[0-9]{7}-[0-9]{5}-[0-9]{5}-[0-9]{6}$","required":true},
            product_name : {"maxLength":"100","type":"string","required":false},
            revision_date : {"type":"string","format":"date","required":false},
            revision_version : {"maxLength":"100","type":"string","required":false},
            supplier_name : {"maxLength":"100","type":"string","required":false},
            language : {"maxLength":"100","type":"string","required":false},
        }
    }
}

export class Users_Login_Profile_type_Documents_Document_code_GET_APIClass extends APIClass {

    params?:Users_Login_Profile_type_Documents_Document_code_GET_APIInterface;
    query?:Users_Login_Profile_type_Documents_Document_code_GET_APIQueryInterface;
    method:METHOD = METHOD.GET;
    url:string = "/users/{login}/{profile_type}/documents/{document_code}";

    constructor(query?:Users_Login_Profile_type_Documents_Document_code_GET_APIQueryInterface, params?:Users_Login_Profile_type_Documents_Document_code_GET_APIInterface) {
        super(query, params);
        if(!query) {
            this.query = {
                login : "",
                profile_type : ENUM_PROFILE_TYPE.MYSDS,
                document_code : "",
            }
        }
    }

     get rules() {
         return {
            login : {"maxLength":"100","type":"string","required":true},
            profile_type : {"type":"string","enum":["mySDS","admin","assistant"],"required":true},
            document_code : {"type":"string","pattern":"^[0-9]{7}-[0-9]{5}-[0-9]{5}-[0-9]{6}$","required":true},
        }
    }
}

export class Contact_technical_support_POST_APIClass extends APIClass {

    params?:Contact_technical_support_POST_APIInterface;
    query?:Contact_technical_support_POST_APIQueryInterface;
    method:METHOD = METHOD.POST;
    url:string = "/contact_technical_support";

    constructor(query?:Contact_technical_support_POST_APIQueryInterface, params?:Contact_technical_support_POST_APIInterface, sendEmpty?:Contact_us_POST_APISendEmpty) {
        super(query, params, sendEmpty);
        if(!params) {
            this.params = {
                    title : "",
                    first_name : "",
                    last_name : "",
                    email : "",
                    phone : "",
                    you_are : "",
                    concern : "",
                    company_name : "",
                    nb_documents: "",
                    message : "",
                    qsds_consent: true,
                    weka_consent: false,
            }
        }
    }

    get rules() {
        return {
            title : {"type":"string","maxLength":"100","required":true},
            first_name : {"pattern":"^[^0-9,;:!?]+$","maxLength":"100","type":"string","required":true},
            last_name : {"pattern":"^[^0-9,;:!?]+$","maxLength":"100","type":"string","required":true},
            email : {"format":"email","type":"string","required":true},
            phone : {"type":"string","x-cmt-pattern":"phone_number","required":true},
            zipcode : {"maxLength":"100","type":"string","required":false},
            you_are : {"maxLength":"100","type":"string","required":true},
            concern : {"maxLength":"100","type":"string","required":true},
            company_name : {"maxLength":"100","type":"string","required":true},
            role : {"maxLength":"100","type":"string","required":false},
            nb_documents : {"maxLength":"100","type":"string","required":true},
            message : {"maxLength":"1000","type":"string","required":true},
            qsds_consent : {"type":"boolean","required":true},
            weka_consent : {"type":"boolean","required":true},
        }
    }
}

export class Cart_GET_APIClass extends APIClass {

    params?:Cart_GET_APIInterface;
    query?:Cart_GET_APIQueryInterface;
    method:METHOD = METHOD.GET;
    url:string = "/cart";

    constructor(query?:Cart_GET_APIQueryInterface, params?:Cart_GET_APIInterface) {
        super(query, params);
    }

     get rules() {
         return {}
    }
}

export class Cart_PUT_APIClass extends APIClass {

    params?:Cart_PUT_APIInterface;
    query?:Cart_PUT_APIQueryInterface;
    method:METHOD = METHOD.PUT;
    requestType:string = 'json';
    url:string = "/cart";

    constructor(query?:Cart_PUT_APIQueryInterface, params?:Cart_PUT_APIInterface) {
        super(query, params);
    }

    get rules() {
        return {
            offer_ids : {"required":true},
            quantity : {"required":true},
        }
    }
}

export class Cart_DELETE_APIClass extends APIClass {

    params?:Cart_DELETE_APIInterface;
    query?:Cart_DELETE_APIQueryInterface;
    method:METHOD = METHOD.DELETE;
    url:string = "/cart";

    constructor(query?:Cart_DELETE_APIQueryInterface, params?:Cart_DELETE_APIInterface) {
        super(query, params);
    }

     get rules() {
         return {
            item_code : {"required":false},
        }
    }
}

export class Cart_POST_APIClass extends APIClass {

    params?:Cart_POST_APIInterface;
    query?:Cart_POST_APIQueryInterface;
    method:METHOD = METHOD.POST;
    url:string = "/cart";

    constructor(query?:Cart_POST_APIQueryInterface, params?:Cart_POST_APIInterface) {
        super(query, params);
        if(!params) {
            this.params = {
                    item_code : [],
            }
        }
    }

     get rules() {
         return {
            item_code : {"required":true},
            level : {"required":false},
        }
    }
}

export class Users_Login_Profile_type_Customer_codes_POST_APIClass extends APIClass {

    params?:Users_Login_Profile_type_Customer_codes_POST_APIInterface;
    query?:Users_Login_Profile_type_Customer_codes_POST_APIQueryInterface;
    method:METHOD = METHOD.POST;
    url:string = "/users/{login}/{profile_type}/customer_codes";

    constructor(query?:Users_Login_Profile_type_Customer_codes_POST_APIQueryInterface, params?:Users_Login_Profile_type_Customer_codes_POST_APIInterface) {
        super(query, params);
        if(!params) {
            this.params = {
                    supplier_name : "",
                    customer_code : "",
            }
        }
        if(!query) {
            this.query = {
                login : "",
                profile_type : ENUM_PROFILE_TYPE.MYSDS,
            }
        }
    }

     get rules() {
         return {
            login : {"maxLength":"100","type":"string","required":true},
            profile_type : {"type":"string","enum":["mySDS","admin","assistant"],"required":true},
            supplier_name : {"maxLength":"100","type":"string","required":true},
            customer_code : {"maxLength":"240","type":"string","required":true},
        }
    }
}

export class Users_Login_Profile_type_Customer_codes_DELETE_APIClass extends APIClass {

    params?:Users_Login_Profile_type_Customer_codes_DELETE_APIInterface;
    query?:Users_Login_Profile_type_Customer_codes_DELETE_APIQueryInterface;
    method:METHOD = METHOD.DELETE;
    url:string = "/users/{login}/{profile_type}/customer_codes";

    constructor(query?:Users_Login_Profile_type_Customer_codes_DELETE_APIQueryInterface, params?:Users_Login_Profile_type_Customer_codes_DELETE_APIInterface) {
        super(query, params);
        if(!params) {
            this.params = {
                    supplier_name : "",
            }
        }
        if(!query) {
            this.query = {
                login : "",
                profile_type : ENUM_PROFILE_TYPE.MYSDS,
            }
        }
    }

     get rules() {
         return {
            login : {"maxLength":"100","type":"string","required":true},
            profile_type : {"type":"string","enum":["mySDS","admin","assistant"],"required":true},
            supplier_name : {"maxLength":"100","type":"string","required":true},
        }
    }
}

export class Users_Login_Profile_type_Customer_codes_GET_APIClass extends APIClass {

    params?:Users_Login_Profile_type_Customer_codes_GET_APIInterface;
    query?:Users_Login_Profile_type_Customer_codes_GET_APIQueryInterface;
    method:METHOD = METHOD.GET;
    url:string = "/users/{login}/{profile_type}/customer_codes";

    constructor(query?:Users_Login_Profile_type_Customer_codes_GET_APIQueryInterface, params?:Users_Login_Profile_type_Customer_codes_GET_APIInterface) {
        super(query, params);
        if(!query) {
            this.query = {
                login : "",
                profile_type : ENUM_PROFILE_TYPE.MYSDS,
            }
        }
    }

     get rules() {
         return {
            login : {"maxLength":"100","type":"string","required":true},
            profile_type : {"type":"string","enum":["mySDS","admin","assistant"],"required":true},
            supplier_name : {"maxLength":"100","type":"string","required":false},
        }
    }
}

export class Signatures_Safebox_POST_APIClass extends APIClass {

    params?:Signatures_Safebox_POST_APIInterface;
    query?:Signatures_Safebox_POST_APIQueryInterface;
    method:METHOD = METHOD.POST;
    url:string = "/signatures/safebox";

    constructor(query?:Signatures_Safebox_POST_APIQueryInterface, params?:Signatures_Safebox_POST_APIInterface) {
        super(query, params);
        if(!params) {
            this.params = {
                    contract_identifier : "",
            }
        }
    }

     get rules() {
         return {
            contract_identifier : {"type":"string","pattern":"^[0-9]{5}-[0-9]{5}-[0-9]{5}-[0-9]{6}$","required":true},
        }
    }
}

export class Users_Login_MySDS_Documents_Changelog_GET_APIClass extends APIClass {

    params?:Users_Login_MySDS_Documents_Changelog_GET_APIInterface;
    query?:Users_Login_MySDS_Documents_Changelog_GET_APIQueryInterface;
    method:METHOD = METHOD.GET;
    url:string = "/users/{login}/mySDS/documents/changelog";

    constructor(query?:Users_Login_MySDS_Documents_Changelog_GET_APIQueryInterface, params?:Users_Login_MySDS_Documents_Changelog_GET_APIInterface) {
        super(query, params);
        if(!query) {
            this.query = {
                login : "",
            }
        }
    }

     get rules() {
         return {
            login : {"maxLength":"100","type":"string","required":true},
            document_code : {"required":false},
            document_col : {"required":false},
            limit : {"required":false},
            offset : {"required":false},
            date_start : {"type":"string","format":"date","required":false},
            date_end : {"format":"date","type":"string","required":false}
        }
    }
}

export class Request_customer_code_POST_APIClass extends APIClass {

    params?:Request_customer_code_POST_APIInterface;
    method:METHOD = METHOD.POST;
    url:string = "/request_customer_code";

    constructor(query?:Request_customer_code_POST_APIQueryInterface, params?:Request_customer_code_POST_APIInterface, sendEmpty?:Request_customer_code_POST_APISendEmpty) {
        super(query, params, sendEmpty);
        if(!params) {
            this.params = {
                supplier_code: "",
                company_name: "",
                address1: "",
                address2: "",
                address3: "",
                zipcode: "",
                city: "",
                name: "",
                email: "",
                phone: "",
                message: "",
                qsds_consent: true,
                weka_consent: false
            }
        }
    }

    get rules() {
        return {
            supplier_code: {"maxLength":"100","type":"string","required":true},
            company_name : {"maxLength":"100","type":"string","required":true},
            address1 : {"maxLength":"200","type":"string","required":true},
            address2 : {"maxLength":"200","type":"string","required":false},
            address3 : {"maxLength":"200","type":"string","required":false},
            zipcode : {"maxLength":"10","type":"string","required":true},
            city : {"maxLength":"100","type":"string","required":true},
            name : {"pattern":"^[^0-9,;:!?]+$","maxLength":"100","type":"string","required":true},
            email : {"type":"string","format":"email","required":true},
            phone : {"type":"string","x-cmt-pattern":"phone_number","required":true},
            message : {"maxLength":"1000","type":"string","required":false},
            qsds_consent : {"type":"boolean","required":true},
            weka_consent : {"type":"boolean","required":true}
        }
    }
}
export class Contact_us_POST_APIClass extends APIClass {

    params?:Contact_us_POST_APIInterface;
    query?:Contact_us_POST_APIQueryInterface;
    method:METHOD = METHOD.POST;
    url:string = "/contact_us";

    constructor(query?:Contact_us_POST_APIQueryInterface, params?:Contact_us_POST_APIInterface, sendEmpty?:Contact_us_POST_APISendEmpty) {
        super(query, params, sendEmpty);
        if(!params) {
            this.params = {
                title: "",
                name: "",
                email: "",
                phone: "",
                you_are: "",
                concern: "",
                company_name: "",
                nb_documents: "",
                how_did_you_hear: "",
                qsds_consent: true,
                weka_consent: false,
            }
        }
    }

     get rules() {
         return {
            title : {"type":"string","maxLength":"100","required":true},
            name : {"pattern":"^[^0-9,;:!?]+$","maxLength":"100","type":"string","required":true},
            email : {"type":"string","format":"email","required":true},
            phone : {"type":"string","x-cmt-pattern":"phone_number","required":true},
            zipcode : {"maxLength":"100","type":"string","required":false},
            you_are : {"maxLength":"100","type":"string","required":true},
            concern : {"maxLength":"100","type":"string","required":true},
            concern_other : {"maxLength":"100","type":"string","required":false},
            company_name : {"maxLength":"100","type":"string","required":true},
            role : {"maxLength":"100","type":"string","required":false},
            message : {"maxLength":"1000","type":"string","required":false},
            nb_documents : {"maxLength":"100","type":"string","required":true},
            how_did_you_hear : {"maxLength":"100","type":"string","required":true},
            how_did_you_hear_other : {"maxLength":"100","type":"string","required":false},
            how_did_you_hear_show : {"maxLength":"100","type":"string","required":false},
            how_did_you_hear_show_other : {"maxLength":"100","type":"string","required":false},
            qsds_consent : {"type":"boolean","required":true},
            weka_consent : {"type":"boolean","required":true},
        }
    }
}

export class Documents_Document_code_Vigi_substances_GET_APIClass extends APIClass {

    params?:Documents_Document_code_Vigi_substances_GET_APIInterface;
    query?:Documents_Document_code_Vigi_substances_GET_APIQueryInterface;
    method:METHOD = METHOD.GET;
    url:string = "/documents/{document_code}/vigi_substances";

    constructor(query?:Documents_Document_code_Vigi_substances_GET_APIQueryInterface, params?:Documents_Document_code_Vigi_substances_GET_APIInterface) {
        super(query, params);
        if(!query) {
            this.query = {
                document_code : "",
            }
        }
    }

    get rules() {
        return {
            document_code : {"type":"string","pattern":"^[0-9]{7}-[0-9]{5}-[0-9]{5}-[0-9]{6}$","required":true},
        }
    }
}

export class Users_Login_MySDS_Documents_Quick_icpe_GET_APIClass extends APIClass {

    params?:Users_Login_MySDS_Documents_Quick_icpe_GET_APIInterface;
    query?:Users_Login_MySDS_Documents_Quick_icpe_GET_APIQueryInterface;
    method:METHOD = METHOD.GET;
    url:string = "/users/{login}/mySDS/documents/quick_icpe";

    constructor(query?:Users_Login_MySDS_Documents_Quick_icpe_GET_APIQueryInterface, params?:Users_Login_MySDS_Documents_Quick_icpe_GET_APIInterface) {
        super(query, params);
        if(!query) {
            this.query = {
                login : "",
            }
        }
    }

     get rules() {
         return {
            login : {"maxLength":"100","type":"string","required":true},
            document_code : {"required":false},
            limit : {"required":false},
            offset : {"required":false},
        }
    }
}

export class Users_Login_MySDS_Documents_Sds2excel_GET_APIClass extends APIClass {

    params?:Users_Login_MySDS_Documents_Sds2excel_GET_APIInterface;
    query?:Users_Login_MySDS_Documents_Sds2excel_GET_APIQueryInterface;
    method:METHOD = METHOD.GET;
    url:string = "/users/{login}/mySDS/documents/sds2excel";

    constructor(query?:Users_Login_MySDS_Documents_Sds2excel_GET_APIQueryInterface, params?:Users_Login_MySDS_Documents_Sds2excel_GET_APIInterface) {
        super(query, params);
        if(!query) {
            this.query = {
                login : "",
            }
        }
    }

     get rules() {
         return {
            login : {"maxLength":"100","type":"string","required":true},
            document_code : {"required":false},
            limit : {"required":false},
            offset : {"required":false},
        }
    }
}

export class Contacts_POST_APIClass extends APIClass {

    params?:Contacts_POST_APIInterface;
    query?:Contacts_POST_APIQueryInterface;
    method:METHOD = METHOD.POST;
    url:string = "/contacts";

    constructor(query?:Contacts_POST_APIQueryInterface, params?:Contacts_POST_APIInterface) {
        super(query, params);
    }

     get rules() {
         return {
            title : {"type":"string","maxLength":"100","required":false},
            first_name : {"pattern":"^[^0-9,;:!?]+$","maxLength":"100","type":"string","required":false},
            last_name : {"pattern":"^[^0-9,;:!?]+$","maxLength":"100","type":"string","required":false},
            email_office : {"format":"email","type":"string","required":false},
            company_name : {"maxLength":"100","type":"string","required":false},
            position : {"maxLength":"100","type":"string","required":false},
            phone_office : {"type":"string","x-cmt-pattern":"phone_number","required":false},
            country_code : {"pattern":"^[a-zA-Z][a-zA-Z]$","type":"string","required":false},
            address1 : {"maxLength":"100","type":"string","required":false},
            address2 : {"maxLength":"100","type":"string","required":false},
            address3 : {"maxLength":"100","type":"string","required":false},
            zipcode : {"maxLength":"100","type":"string","required":false},
            city : {"maxLength":"100","type":"string","required":false},
            state : {"maxLength":"100","type":"string","required":false},
            duns : {"pattern":"^[0-9]{9}$","type":"string","required":false},
            siret : {"pattern":"^[0-9]{14}$","type":"string","required":false},
        }
    }
}

export class Users_Login_PUT_APIClass extends APIClass {

    params?:Users_Login_PUT_APIInterface;
    query?:Users_Login_PUT_APIQueryInterface;
    method:METHOD = METHOD.PUT;
    url:string = "/users/{login}";

    constructor(query?:Users_Login_PUT_APIQueryInterface, params?:Users_Login_PUT_APIInterface, sendEmpty?:Users_Login_PUT_APISendEmpty) {
        super(query, params, sendEmpty);
        if(!query) {
            this.query = {
                login : "",
            }
        }
    }

    get rules() {
        return {
            token : {"type":"string","maxLength":"1000","required":false},
            login : {"maxLength":"100","type":"string","required":true},
            password : {"maxLength":"200","format":"password","minLength":"8","type":"string","required":false},
            first_name : {"pattern":"^[^0-9,;:!?]+$","maxLength":"100","type":"string","required":true},
            last_name : {"pattern":"^[^0-9,;:!?]+$","maxLength":"100","type":"string","required":true},
            email : {"type":"string","format":"email","required":false},
            email_rescue : {"type":"string","format":"email","required":false},
            company_name : {"maxLength":"100","type":"string","required":true},
            country_code : {"pattern":"^[a-zA-Z][a-zA-Z]$","type":"string","required":false},
            address1 : {"maxLength":"100","type":"string","required":false},
            address2 : {"maxLength":"100","type":"string","required":false},
            address3 : {"maxLength":"100","type":"string","required":false},
            zipcode : {"maxLength":"100","type":"string","required":false},
            city : {"maxLength":"100","type":"string","required":false},
            state : {"maxLength":"100","type":"string","required":false},
            duns : {"pattern":"^[0-9]{9}$","type":"string","required":false},
            siret : {"pattern":"^[0-9]{14}$","type":"string","required":false},
            title : {"type":"string","maxLength":"100","required":false},
            department : {"type":"string","maxLength":"300","required":false},
            position : {"type":"string","maxLength":"300","required":false},
            phone_office : {"type":"string","x-cmt-pattern":"phone_number","required":false},
            eu_vat_number : {"type":"string","required":false},
        }
    }
}
export class Users_Login_Billing_POST_APIClass extends APIClass {

    params?:Users_Login_PUT_APIInterface;
    query?:Users_Login_PUT_APIQueryInterface;
    method:METHOD = METHOD.POST;
    url:string = "/users/{login}/billing";

    constructor (query?:Users_Login_PUT_APIQueryInterface, params?:Users_Login_PUT_APIInterface, sendEmpty?:Users_Login_PUT_APISendEmpty) {
        super(query, params, sendEmpty);
        if (!query) {
            this.query = {
                login : ""
            }
        }
    }

    get rules() {
        return {
            token : {"type":"string","maxLength":"1000","required":false},
            login : {"maxLength":"100","type":"string","required":true},
            password : {"maxLength":"200","format":"password","minLength":"8","type":"string","required":false},
            first_name : {"pattern":"^[^0-9,;:!?]+$","maxLength":"100","type":"string","required":true},
            last_name : {"pattern":"^[^0-9,;:!?]+$","maxLength":"100","type":"string","required":true},
            email : {"type":"string","format":"email","required":false},
            email_rescue : {"type":"string","format":"email","required":false},
            safebox_cc : {"type":"string","format":"email","required":false},
            company_name : {"maxLength":"100","type":"string","required":true},
            country_code : {"pattern":"^[a-zA-Z][a-zA-Z]$","type":"string","required":false},
            address1 : {"maxLength":"100","type":"string","required":false},
            address2 : {"maxLength":"100","type":"string","required":false},
            address3 : {"maxLength":"100","type":"string","required":false},
            zipcode : {"maxLength":"100","type":"string","required":false},
            city : {"maxLength":"100","type":"string","required":false},
            state : {"maxLength":"100","type":"string","required":false},
            duns : {"pattern":"^[0-9]{9}$","type":"string","required":false},
            siret : {"pattern":"^[0-9]{14}$","type":"string","required":false},
            title : {"type":"string","maxLength":"100","required":false},
            department : {"type":"string","maxLength":"300","required":false},
            position : {"type":"string","maxLength":"300","required":false},
            phone_office : {"type":"string","x-cmt-pattern":"phone_number","required":false},
            eu_vat_number : {"type":"string","required":false},
            qsds_consent : {"type":"boolean","required":false},
            weka_consent : {"type":"boolean","required":false},
        }
    }
}
export class Users_Login_Command_DELETE_APIClass extends APIClass {

    query?:Users_Login_Command_DELETE_APIQueryInterface;
    method:METHOD = METHOD.DELETE;
    url:string = "/users/{login}/command";

    constructor (query?:Users_Login_Command_DELETE_APIQueryInterface) {
        super(query);
        if (!query) {
            this.query = {
                login : ""
            }
        }
    }

    get rules() {
        return {
            login : {"maxLength":"100","type":"string","required":true},
        }
    }
}
export class Users_Login_Command_POST_APIClass extends APIClass {

    query?:Users_Login_Command_POST_APIQueryInterface;
    method:METHOD = METHOD.POST;
    url:string = "/users/{login}/command";

    constructor (query?:Users_Login_Command_POST_APIQueryInterface) {
        super(query);
        if (!query) {
            this.query = {
                login : ""
            }
        }
    }

    get rules() {
        return {
            login : {"maxLength":"100","type":"string","required":true},
        }
    }
}
export class Users_Login_Command_GET_APIClass extends APIClass {

    query?:Users_Login_Command_GET_APIQueryInterface;
    method:METHOD = METHOD.GET;
    url:string = "/users/{login}/command";

    constructor (query?:Users_Login_Command_GET_APIQueryInterface) {
        super(query);
        if (!query) {
            this.query = {
                login : ""
            }
        }
    }

    get rules() {
        return {
            login : {"maxLength":"100","type":"string","required":true},
        }
    }
}


export class Users_Login_Safebox_POST_APIClass extends APIClass {

    params?:Users_Login_PUT_APIInterface;
    query?:Users_Login_PUT_APIQueryInterface;
    method:METHOD = METHOD.POST;
    url:string = "/users/{login}/safebox";

    constructor (query?:Users_Login_PUT_APIQueryInterface, params?:Users_Login_PUT_APIInterface, sendEmpty?:Users_Login_PUT_APISendEmpty) {
        super(query, params, sendEmpty);
        if (!query) {
            this.query = {
                login : ""
            }
        }
    }

    get rules() {
        return {
            token : {"type":"string","maxLength":"1000","required":false},
            login : {"maxLength":"100","type":"string","required":true},
            password : {"maxLength":"200","format":"password","minLength":"8","type":"string","required":false},
            first_name : {"pattern":"^[^0-9,;:!?]+$","maxLength":"100","type":"string","required":true},
            last_name : {"pattern":"^[^0-9,;:!?]+$","maxLength":"100","type":"string","required":true},
            email : {"type":"string","format":"email","required":false},
            email_rescue : {"type":"string","format":"email","required":false},
            safebox_cc : {"type":"string","format":"email","required":false},
            company_name : {"maxLength":"100","type":"string","required":true},
            country_code : {"pattern":"^[a-zA-Z][a-zA-Z]$","type":"string","required":false},
            address1 : {"maxLength":"100","type":"string","required":false},
            address2 : {"maxLength":"100","type":"string","required":false},
            address3 : {"maxLength":"100","type":"string","required":false},
            zipcode : {"maxLength":"100","type":"string","required":false},
            city : {"maxLength":"100","type":"string","required":false},
            state : {"maxLength":"100","type":"string","required":false},
            duns : {"pattern":"^[0-9]{9}$","type":"string","required":false},
            siret : {"pattern":"^[0-9]{14}$","type":"string","required":false},
            title : {"type":"string","maxLength":"100","required":false},
            department : {"type":"string","maxLength":"300","required":false},
            position : {"type":"string","maxLength":"300","required":false},
            phone_office : {"type":"string","x-cmt-pattern":"phone_number","required":false},
            eu_vat_number : {"type":"string","required":false},
            qsds_consent : {"type":"boolean","required":false},
            weka_consent : {"type":"boolean","required":false},
        }
    }
}

export class Users_Login_Modify_email_request_POST_APIClass extends APIClass {

    params?:Users_Login_Modify_email_request_POST_APIInterface;
    query?:Users_Login_Modify_email_request_POST_APIQueryInterface;
    method:METHOD = METHOD.POST;
    url:string = "/users/{login}/modify_email_request";

    constructor(query?:Users_Login_Modify_email_request_POST_APIQueryInterface, params?:Users_Login_Modify_email_request_POST_APIInterface) {
        super(query, params);
        if(!params) {
            this.params = {
                    email : "",
            }
        }
        if(!query) {
            this.query = {
                login : "",
            }
        }
    }

     get rules() {
         return {
            login : {"maxLength":"100","type":"string","required":true},
            email : {"type":"string","format":"email","required":true},
        }
    }
}

export class Users_Login_Email_PUT_APIClass extends APIClass {

    params?:Users_Login_Email_PUT_APIInterface;
    query?:Users_Login_Email_PUT_APIQueryInterface;
    method:METHOD = METHOD.PUT;
    url:string = "/users/{login}/email/modification";

    constructor(query?:Users_Login_Email_PUT_APIQueryInterface, params?:Users_Login_Email_PUT_APIInterface) {
        super(query, params);
        if(!params) {
            this.params = {
                token : "",
                password : "",
                email : "",
            }
        }
        if(!query) {
            this.query = {
                login : "",
            }
        }
    }

     get rules() {
        return {
            token : {"type":"string","maxLength":"1000","required":true},
            login : {"maxLength":"100","type":"string","required":true},
            email : {"type":"string","format":"email","required":true},
            password : {"type":"string","maxLength":"200","format":"password","minLength":"1","required":true},
        }
    }
}

export class Documents_Document_code_Quick_adr_GET_APIClass extends APIClass {

    params?:Documents_Document_code_Quick_adr_GET_APIInterface;
    query?:Documents_Document_code_Quick_adr_GET_APIQueryInterface;
    method:METHOD = METHOD.GET;
    url:string = "/documents/{document_code}/quick_adr";

    constructor(query?:Documents_Document_code_Quick_adr_GET_APIQueryInterface, params?:Documents_Document_code_Quick_adr_GET_APIInterface) {
        super(query, params);
        if(!query) {
            this.query = {
                document_code : "",
            }
        }
    }

     get rules() {
         return {
            document_code : {"type":"string","pattern":"^[0-9]{7}-[0-9]{5}-[0-9]{5}-[0-9]{6}$","required":true},
        }
    }
}

export class Documents_Document_code_Quick_icpe_GET_APIClass extends APIClass {

    params?:Documents_Document_code_Quick_icpe_GET_APIInterface;
    query?:Documents_Document_code_Quick_icpe_GET_APIQueryInterface;
    method:METHOD = METHOD.GET;
    url:string = "/documents/{document_code}/quick_icpe";

    constructor(query?:Documents_Document_code_Quick_icpe_GET_APIQueryInterface, params?:Documents_Document_code_Quick_icpe_GET_APIInterface) {
        super(query, params);
        if(!query) {
            this.query = {
                document_code : "",
            }
        }
    }

     get rules() {
         return {
            document_code : {"type":"string","pattern":"^[0-9]{7}-[0-9]{5}-[0-9]{5}-[0-9]{6}$","required":true},
        }
    }
}

export class Documents_Document_code_GET_APIClass extends APIClass {

    params?:Documents_Document_code_GET_APIInterface;
    query?:Documents_Document_code_GET_APIQueryInterface;
    method:METHOD = METHOD.GET;
    url:string = "/documents/{document_code}";

    constructor(query?:Documents_Document_code_GET_APIQueryInterface, params?:Documents_Document_code_GET_APIInterface) {
        super(query, params);
        if(!query) {
            this.query = {
                document_code : "",
            }
        }
    }

     get rules() {
         return {
            document_code : {"type":"string","pattern":"^[0-9]{7}-[0-9]{5}-[0-9]{5}-[0-9]{6}$","required":true},
        }
    }
}

export class Documents_Document_code_Qrcode_GET_APIClass extends APIClass {

    params?:Documents_Document_code_Qrcode_GET_APIInterface;
    query?:Documents_Document_code_Qrcode_GET_APIQueryInterface;
    method:METHOD = METHOD.GET;
    url:string = "/documents/{document_code}/qrcode";

    constructor(query?:Documents_Document_code_Qrcode_GET_APIQueryInterface, params?:Documents_Document_code_Qrcode_GET_APIInterface) {
        super(query, params);
        if(!query) {
            this.query = {
                document_code : "",
            }
        }
    }

     get rules() {
         return {
            document_code : {"type":"string","pattern":"^[0-9]{7}-[0-9]{5}-[0-9]{5}-[0-9]{6}$","required":true},
        }
    }
}

export class Users_POST_APIClass extends APIClass {

    params?:Users_POST_APIInterface;
    query?:Users_POST_APIQueryInterface;
    method:METHOD = METHOD.POST;
    url:string = "/users";

    constructor(query?:Users_POST_APIQueryInterface, params?:Users_POST_APIInterface) {
        super(query, params);
        if(!params) {
            this.params = {
                    token : "",
                    password : "",
            }
        }
    }

     get rules() {
         return {
            token : {"type":"string","maxLength":"1000","required":true},
            password : {"maxLength":"200","format":"password","minLength":"8","type":"string","required":true},
        }
    }
}

export class Search_steps_GET_APIClass extends APIClass {

    params?:Search_steps_GET_APIInterface;
    query?:Search_steps_GET_APIQueryInterface;
    method:METHOD = METHOD.GET;
    url:string = "/search_steps";

    constructor(query?:Search_steps_GET_APIQueryInterface, params?:Search_steps_GET_APIInterface) {
        super(query, params);
        if(!params) {
            this.params = {
                    supplier_name : "",
            }
        }
    }

     get rules() {
         return {
            supplier_name : {"maxLength":"100","type":"string","required":true},
        }
    }
}

export class Search_next_steps_GET_APIClass extends APIClass {

    query?:Search_steps_GET_APIQueryInterface;
    method:METHOD = METHOD.GET;
    url:string = "/search_steps/{step_code}";

    constructor(query?:Search_steps_GET_APIQueryInterface) {
        super(query);
        if(!query) {
            this.query = {
                    step_code : "",
            }
        }
    }

     get rules() {
         return {
            supplier_name : {"maxLength":"100","type":"string","required":true},
        }
    }
}

export class Session_DELETE_APIClass extends APIClass {

    params?:Session_DELETE_APIInterface;
    query?:Session_DELETE_APIQueryInterface;
    method:METHOD = METHOD.DELETE;
    url:string = "/session";

    constructor(query?:Session_DELETE_APIQueryInterface, params?:Session_DELETE_APIInterface) {
        super(query, params);
    }

     get rules() {
         return {}
    }
}

export class Session_POST_APIClass extends APIClass {

    params?:Session_POST_APIInterface;
    query?:Session_POST_APIQueryInterface;
    method:METHOD = METHOD.POST;
    url:string = "/session";

    constructor(query?:Session_POST_APIQueryInterface, params?:Session_POST_APIInterface) {
        super(query, params);
        if(!params) {
            this.params = {
                    login : "",
                    password : "",
            }
        }
    }

     get rules() {
         return {
            login : {"maxLength":"100","type":"string","format":"login","required":true},
            password : {"type":"string","maxLength":"200","minLength":"1","required":true},
        }
    }
}

export class Session_GET_APIClass extends APIClass {

    params?:Session_GET_APIInterface;
    query?:Session_GET_APIQueryInterface;
    method:METHOD = METHOD.GET;
    url:string = "/session";

    constructor(query?:Session_GET_APIQueryInterface, params?:Session_GET_APIInterface) {
        super(query, params);
    }

     get rules() {
         return {}
    }
}

export class Session_PUT_APIClass extends APIClass {

    params?:Session_PUT_APIInterface;
    query?:Session_PUT_APIQueryInterface;
    method:METHOD = METHOD.PUT;
    url:string = "/session";

    constructor(query?:Session_PUT_APIQueryInterface, params?:Session_PUT_APIInterface, sendEmpty?:Session_PUT_APISendEmpty) {
        super(query, params, sendEmpty);
    }

     get rules() {
         return {
            first_name : {"pattern":"^[^0-9,;:!?]+$","maxLength":"100","type":"string","required":true},
            last_name : {"pattern":"^[^0-9,;:!?]+$","maxLength":"100","type":"string","required":true},
            company_name : {"maxLength":"100","type":"string","required":true},
            email : {"format":"email","type":"string","required":true},
            qsds_consent : {"type":"boolean","required":false},
            weka_consent : {"type":"boolean","required":false},
        }
    }
}

export class Users_Login_MySDS_Documents_Vigi_substances_GET_APIClass extends APIClass {

    params?:Users_Login_MySDS_Documents_Vigi_substances_GET_APIInterface;
    query?:Users_Login_MySDS_Documents_Vigi_substances_GET_APIQueryInterface;
    method:METHOD = METHOD.GET;
    url:string = "/users/{login}/mySDS/documents/vigi_substances";

    constructor(query?:Users_Login_MySDS_Documents_Vigi_substances_GET_APIQueryInterface, params?:Users_Login_MySDS_Documents_Vigi_substances_GET_APIInterface) {
        super(query, params);
        if(!query) {
            this.query = {
                login : "",
            }
        }
    }

     get rules() {
         return {
            login : {"maxLength":"100","type":"string","required":true},
            document_code : {"required":false},
            limit : {"required":false},
            offset : {"required":false},
        }
    }
}

export class Users_Login_MySDS_Documents_Quick_adr_GET_APIClass extends APIClass {

    params?:Users_Login_MySDS_Documents_Quick_adr_GET_APIInterface;
    query?:Users_Login_MySDS_Documents_Quick_adr_GET_APIQueryInterface;
    method:METHOD = METHOD.GET;
    url:string = "/users/{login}/mySDS/documents/quick_adr";

    constructor(query?:Users_Login_MySDS_Documents_Quick_adr_GET_APIQueryInterface, params?:Users_Login_MySDS_Documents_Quick_adr_GET_APIInterface) {
        super(query, params);
        if(!query) {
            this.query = {
                login : "",
            }
        }
    }

     get rules() {
         return {
            login : {"maxLength":"100","type":"string","required":true},
            document_code : {"required":false},
            limit : {"required":false},
            offset : {"required":false},
        }
    }
}
export class Users_Login_MySDS_Documents_Export_GET_APIClass extends APIClass {

    params?:Users_Login_MySDS_Documents_Export_GET_APIInterface;
    query?:Users_Login_MySDS_Documents_GET_APIQueryInterface;
    method:METHOD = METHOD.GET;
    responseType:string = 'blob';
    url:string = "/users/{login}/mySDS/documents/export";

    constructor(query?:Users_Login_Profile_type_Documents_GET_APIQueryInterface, params?:Users_Login_MySDS_Documents_Export_GET_APIInterface) {
        super(query, params);
        if(!query) {
            this.query = {
                login : "",
            }
        }
    }
     get rules() {
         return {
            login : {"maxLength":"100","type":"string","required":true},
            profile_type : ENUM_PROFILE_TYPE.MYSDS
        }
    }
}
export class Report_Data_GET_APIClass extends APIClass {

    query:Report_Data_GET_APIQueryInterface;
    method:METHOD = METHOD.GET;
    url:string = "/rapport/donnees_fds/{document_code}";
    responseType:string = 'blob';

    constructor(query:Report_Data_GET_APIQueryInterface) {
        super(query);
    }
    get rules() {
        return {}
    }
}
export class Report_Vigi_GET_APIClass extends APIClass {

    query:Report_Vigi_GET_APIQueryInterface;
    method:METHOD = METHOD.GET;
    url:string = "/rapport/vigi_substances/{document_code}";
    responseType:string = 'blob';

    constructor(query:Report_Vigi_GET_APIQueryInterface) {
        super(query);
    }
    get rules() {
        return {}
    }
}

export class Users_Login_Profile_type_Documents_POST_APIClass extends APIClass {

    params?:Users_Login_Profile_type_Documents_POST_APIInterface;
    query?:Users_Login_Profile_type_Documents_POST_APIQueryInterface;
    method:METHOD = METHOD.POST;
    url:string = "/users/{login}/{profile_type}/documents";

    constructor(query?:Users_Login_Profile_type_Documents_POST_APIQueryInterface, params?:Users_Login_Profile_type_Documents_POST_APIInterface) {
        super(query, params);
        if(!query) {
            this.query = {
                login : "",
                profile_type : ENUM_PROFILE_TYPE.MYSDS,
            }
        }
    }

     get rules() {
         return {
            login : {"maxLength":"100","type":"string","required":true},
            profile_type : {"type":"string","enum":["mySDS","admin","assistant"],"required":true},
            document_code : {"required":false},
            file : {"format":"binary","type":"string","required":false},
            product_name : {"maxLength":"100","type":"string","required":false},
            revision_date : {"type":"string","format":"date","required":false},
            revision_version : {"maxLength":"100","type":"string","required":false},
            supplier_name : {"maxLength":"100","type":"string","required":false},
            language : {"maxLength":"100","type":"string","required":false},
        }
    }
}
export class Users_Login_Profile_type_Documents_Document_code_SelectInfReg_PUT_APIClass extends APIClass {

    query?:Users_Login_Profile_type_Documents_Document_code_SelectInfReg_PUT_APIQueryInterface;
    method:METHOD = METHOD.PUT;
    url:string = "/users/{login}/{profile_type}/documents/{document_code}/selectInfReg";

    constructor(query?:Users_Login_Profile_type_Documents_Document_code_SelectInfReg_PUT_APIQueryInterface) {
        super(query);
        if(!query) {
            this.query = {
                login : "",
                document_code : "",
                profile_type : ENUM_PROFILE_TYPE.MYSDS,
            }
        }
    }

     get rules() {
         return {
            login : {"maxLength":"100","type":"string","required":true},
            document_code : {"required":true},
        }
    }
}

export class Users_Login_Profile_type_Documents_DELETE_APIClass extends APIClass {

    params?:Users_Login_Profile_type_Documents_DELETE_APIInterface;
    query?:Users_Login_Profile_type_Documents_DELETE_APIQueryInterface;
    method:METHOD = METHOD.DELETE;
    url:string = "/users/{login}/{profile_type}/documents";

    constructor(query?:Users_Login_Profile_type_Documents_DELETE_APIQueryInterface, params?:Users_Login_Profile_type_Documents_DELETE_APIInterface) {
        super(query, params);
        if(!query) {
            this.query = {
                login : "",
                profile_type : ENUM_PROFILE_TYPE.MYSDS,
            }
        }
    }

     get rules() {
         return {
            login : {"maxLength":"100","type":"string","required":true},
            profile_type : {"type":"string","enum":["mySDS","admin","assistant"],"required":true},
        }
    }
}

export class Users_Login_Profile_type_Documents_GET_APIClass extends APIClass {

    params?:Users_Login_Profile_type_Documents_GET_APIInterface;
    query?:Users_Login_Profile_type_Documents_GET_APIQueryInterface;
    method:METHOD = METHOD.GET;
    url:string = "/users/{login}/{profile_type}/documents";

    constructor(query?:Users_Login_Profile_type_Documents_GET_APIQueryInterface, params?:Users_Login_Profile_type_Documents_GET_APIInterface) {
        super(query, params);
        if(!query) {
            this.query = {
                login : "",
                profile_type : ENUM_PROFILE_TYPE.MYSDS,
            }
        }
    }

     get rules() {
         return {
            login : {"maxLength":"100","type":"string","required":true},
            profile_type : {"type":"string","enum":["mySDS","admin","assistant"],"required":true},
            document_code : {"required":false},
            document_col : {"required":false},
            limit : {"required":false},
            offset : {"required":false},
            product_name : {"maxLength":"100","type":"string","required":false},
            supplier_name : {"maxLength":"100","type":"string","required":false},
            sort_by : {"required":false},
            sort_direction : {"required":false},
        }
    }
}

export class Users_Login_Profile_type_PUT_APIClass extends APIClass {

    params?:Users_Login_Profile_type_PUT_APIInterface;
    query?:Users_Login_Profile_type_PUT_APIQueryInterface;
    method:METHOD = METHOD.PUT;
    url:string = "/users/{login}/{profile_type}";

    constructor(query?:Users_Login_Profile_type_PUT_APIQueryInterface, params?:Users_Login_Profile_type_PUT_APIInterface) {
        super(query, params);
        if(!query) {
            this.query = {
                login : "",
                profile_type : ENUM_PROFILE_TYPE.MYSDS,
            }
        }
    }

     get rules() {
         return {
            login : {"maxLength":"100","type":"string","required":true},
            profile_type : {"type":"string","enum":["mySDS","admin","assistant"],"required":true},
            assignment_letter_referring_contact : {"required":false},
            operational_contact : {"required":false},
            safebox : {"required":false},
            safebox_cc : {"format":"email","type":"string","required":false},
            history_imported : {"type":"string","format":"date-time","required":false},
        }
    }
}

export class Users_Modify_password_request_POST_APIClass extends APIClass {

    params?:Users_Modify_password_request_POST_APIInterface;
    query?:Users_Modify_password_request_POST_APIQueryInterface;
    method:METHOD = METHOD.POST;
    url:string = "/users/modify_password_request";

    constructor(query?:Users_Modify_password_request_POST_APIQueryInterface, params?:Users_Modify_password_request_POST_APIInterface) {
        super(query, params);
        if(!params) {
            this.params = {
                    login : "",
            }
        }
    }

     get rules() {
         return {
            login : {"maxLength":"100","type":"string","required":true},
        }
    }
}

export class Users_Login_Profiles_POST_APIClass extends APIClass {

    params?:Users_Login_Profiles_POST_APIInterface;
    query?:Users_Login_Profiles_POST_APIQueryInterface;
    method:METHOD = METHOD.POST;
    url:string = "/users/{login}/profiles";

    constructor(query?:Users_Login_Profiles_POST_APIQueryInterface, params?:Users_Login_Profiles_POST_APIInterface) {
        super(query, params);
        if(!query) {
            this.query = {
                login : "",
            }
        }
    }

     get rules() {
         return {
            login : {"maxLength":"100","type":"string","required":true},
            profile_type : {"type":"string","enum":["mySDS","admin","assistant"],"required":false},
        }
    }
}

export class Users_Login_Admin_GET_APIClass extends APIClass {

    params?:Users_Login_Admin_GET_APIInterface;
    query?:Users_Login_Admin_GET_APIQueryInterface;
    method:METHOD = METHOD.GET;
    url:string = "/users/{login}/admin";

    constructor(query?:Users_Login_Admin_GET_APIQueryInterface, params?:Users_Login_Admin_GET_APIInterface) {
        super(query, params);
        if(!query) {
            this.query = {
                login : "",
            }
        }
    }

     get rules() {
         return {
            login : {"maxLength":"100","type":"string","required":true},
        }
    }
}

export class Users_Login_Roles_GET_APIClass extends APIClass {

    params?:Users_Login_Roles_GET_APIInterface;
    query?:Users_Login_Roles_GET_APIQueryInterface;
    method:METHOD = METHOD.GET;
    url:string = "/users/{login}/roles";

    constructor(query?:Users_Login_Roles_GET_APIQueryInterface, params?:Users_Login_Roles_GET_APIInterface) {
        super(query, params);
        if(!query) {
            this.query = {
                login : "",
            }
        }
    }

     get rules() {
         return {
            login : {"maxLength":"100","type":"string","required":true},
        }
    }
}

export class Users_Subscription_GET_APIClass extends APIClass {

    params?:Users_Subscription_GET_APIInterface;
    query?:Users_Subscription_GET_APIQueryInterface;
    method:METHOD = METHOD.GET;
    url:string = "/users/{login}/subscription";

    constructor(query?:Users_Subscription_GET_APIQueryInterface, params?:Users_Subscription_GET_APIInterface) {
        super(query, params);
        if(!query) {
            this.query = {
                login : "",
            }
        }
    }

     get rules() {
         return {
            login : {"maxLength":"100","type":"string","required":true},
        }
    }
}

export class Cart_Checkout_POST_APIClass extends APIClass {

    params?:Cart_Checkout_POST_APIInterface;
    query?:Cart_Checkout_POST_APIQueryInterface;
    method:METHOD = METHOD.POST;
    url:string = "/cart/checkout";

    constructor(query?:Cart_Checkout_POST_APIQueryInterface, params?:Cart_Checkout_POST_APIInterface) {
        super(query, params);
    }

     get rules() {
         return {}
    }
}

export class Users_Login_Profile_type_History_GET_APIClass extends APIClass {

    params?:Users_Login_Profile_type_History_GET_APIInterface;
    query?:Users_Login_Profile_type_History_GET_APIQueryInterface;
    method:METHOD = METHOD.GET;
    url:string = "/users/{login}/{profile_type}/history";

    constructor(query?:Users_Login_Profile_type_History_GET_APIQueryInterface, params?:Users_Login_Profile_type_History_GET_APIInterface) {
        super(query, params);
        if(!query) {
            this.query = {
                login : "",
                profile_type : ENUM_PROFILE_TYPE.MYSDS,
            }
        }
    }

     get rules() {
         return {
            login : {"maxLength":"100","type":"string","required":true},
            profile_type : {"type":"string","enum":["mySDS","admin","assistant"],"required":true},
            ignore_profile_documents : {"required":false},
        }
    }
}

export class Users_Login_Profile_type_Import_consultation_POST_APIClass extends APIClass {

    //users/{login}/{profile_type}/import_consultation

    query?:Users_Login_Profile_type_Import_consultation_POST_APIQueryInterface;
    method:METHOD = METHOD.POST;
    url:string = "/users/{login}/{profile_type}/import_consultation";

    constructor(query?:Users_Login_Profile_type_History_GET_APIQueryInterface) {
        super(query);
        if(!query) {
            this.query = {
                login : "",
                profile_type : ENUM_PROFILE_TYPE.MYSDS,
            }
        }
    }

    get rules() {
        return {
            login : {"maxLength":"100","type":"string","required":true},
            profile_type : {"type":"string","enum":["mySDS","admin","assistant"],"required":true},
        }
    }
}

export class Suppliers_Supplier_code_Products_Product_code_GET_APIClass extends APIClass {

    params?:Suppliers_Supplier_code_Products_Product_code_GET_APIInterface;
    query?:Suppliers_Supplier_code_Products_Product_code_GET_APIQueryInterface;
    method:METHOD = METHOD.GET;
    url:string = "/suppliers/{supplier_code}/products/{product_code}";

    constructor(query?:Suppliers_Supplier_code_Products_Product_code_GET_APIQueryInterface, params?:Suppliers_Supplier_code_Products_Product_code_GET_APIInterface) {
        super(query, params);
        if(!query) {
            this.query = {
                supplier_code : "",
                product_code : "",
            }
        }
    }

     get rules() {
         return {
            supplier_code : {"maxLength":"100","type":"string","required":true},
            product_code : {"maxLength":"100","type":"string","required":true},
        }
    }
}

export class Catalog_GET_APIClass extends APIClass {

    params?:Catalog_GET_APIInterface;
    query?:Catalog_GET_APIQueryInterface;
    method:METHOD = METHOD.GET;
    url:string = "/tools";

    constructor(query?:Catalog_GET_APIQueryInterface, params?:Catalog_GET_APIInterface) {
        super(query, params);
    }

     get rules() {
         return {}
    }
}

export class Open_Fds_GET_APIClass extends APIClass {

    params?:Open_Fds_GET_APIInterface;
    query:Open_Fds_GET_APIQueryInterface;
    method:METHOD = METHOD.GET;
    url:string = "/documents/{documentCode}";

    constructor(query?:Open_Fds_GET_APIQueryInterface,  params?:Open_Fds_GET_APIInterface) {
        super(query,params);
    }

     get rules() {
         return {}
    }
}

export class Users_Admin_GET_APIClass extends APIClass {

    query:Users_Admin_GET_APIQueryInterface;
    method:METHOD = METHOD.GET;
    url:string = "{adminUrl}";

    constructor(query?:Users_Admin_GET_APIQueryInterface) {
        super(query);
        
        if(!query) {
            this.query = {
                adminUrl : "",
            }
        }
    }

     get rules() {
         return {}
    }
}

export class List_criterias_GET_APIClass extends APIClass {

    params?:List_criterias_GET_APIInterface;
    query?:List_criterias_GET_APIQueryInterface;
    method:METHOD = METHOD.GET;
    url:string = "/search_steps/values_list";

    constructor(query?:List_criterias_GET_APIQueryInterface ,params?:List_criterias_GET_APIInterface) {
        super(query,params);
    }

     get rules() {
         return {}
    }
}
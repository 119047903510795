import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'intersection-observer' // Optional
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'

const Promise = require('es6-promise').Promise;
import App from './App/component.vue';
import router from './router';
import store from './store';
import { sync } from 'vuex-router-sync' ;
import { i18n } from './internationalisation/i18n';
// @ts-ignore
import VueMatomo from 'vue-matomo'
import VueMeta from 'vue-meta'
import VueGlobals from './vue-globals'


// components imported here are global and can be used anywhere without having to be imported
import wCheckbox from '@/widgets/Checkbox/component.vue';
import wLoader from '@/widgets/Loader/component.vue';
import wSelect from '@/widgets/Select/component.vue';
import wFileInput from '@/widgets/FileInput/component.vue';
import wSwitch from '@/widgets/Switch/component.vue';
import wChecked from '@/widgets/Checked/component.vue';
import wBlogVignette from '@/widgets/BlogVignette/component.vue';
import wConsulting from '@/widgets/Consulting/component.vue';
import wBarStats from '@/widgets/BarStats/component.vue';
import wKnowMore from '@/widgets/KnowMore/component.vue';
import wContactUs from '@/widgets/ContactUs/component.vue';

Vue.component('w-checkbox', wCheckbox);
Vue.component('w-loader', wLoader);
Vue.component('w-select', wSelect);
Vue.component('w-file-input', wFileInput);
Vue.component('w-switch', wSwitch);
Vue.component('w-checked', wChecked);
Vue.component('w-blog-vignette', wBlogVignette);
Vue.component('w-consulting', wConsulting);
Vue.component('w-bar-stats', wBarStats);
Vue.component('w-know-more', wKnowMore);
Vue.component('w-contact-us', wContactUs);
Vue.mixin({
    methods: {
        gMixinHandleClicks: function (event:any) {
            // ensure we use the link, in case the click has been received by a subelement
            let { target } = event;
            while (target && target.tagName !== 'A') target = target.parentNode;
            // handle only links that occur inside the component and do not reference external resources
            //if (target && target.matches(".dynamic-content a:not([href*='://'])") && target.href) {
            if (target && target.matches("a:not([href*='://'])") && target.href) {
                // some sanity checks taken from vue-router:
                // https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
                const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = event;
                // don't handle with control keys
                if (metaKey || altKey || ctrlKey || shiftKey) return;
                // don't handle when preventDefault called
                else if (defaultPrevented) return;
                // don't handle right clicks
                else if (button !== undefined && button !== 0) return;
                // don't handle if `target="_blank"`
                else if (target && target.getAttribute) {
                    const linkTarget = target.getAttribute('target');
                    if (/\b_blank\b/i.test(linkTarget)) return;
                }
                
                const url = new URL(target.href);
                const to = url.pathname + url.hash;
                if (window.location.pathname !== to && event.preventDefault) {
                    event.preventDefault();
                    this.$router.push(to);
                }
            }
        },
        $sendEventToMatomo: function (arg1:string, arg2:string) {
            if (process.env.NODE_ENV === 'dev') {
                console.log('trackEvent', arg1, arg2)
            } else {
                // checking for cookieBot's consent
                if (window.hasOwnProperty('CookieConsent')) {
                    // checking consent type
                    if ([
                        'Search',
                        'Login',
                        'UploadFDS',
                        'Consult-FDS',
                        'Telechargement-FDS',
                        'CTA-FDS',
                        'CTA-Contact',
                    ].includes(arg1) && (<any>window).CookieConsent.consent.statistics) {
                        //@ts-ignore
                        const _paq = window._paq = window._paq || '';
                        _paq.push(['trackEvent', arg1, arg2]);
                    }
                }
            }
        }
    }
})

const unsync = sync(store, router) // done. Returns an unsync callback fn

Vue.use(VueMatomo, {
    host: 'https://quickfds.matomo.cloud',
    siteId: !window.location.hostname.includes("beta") ? 1 : 2,
    router,
});

Vue.use(BootstrapVue);
Vue.config.productionTip = false;

Vue.use(VueMeta);
Vue.use(VueGlobals);
// Object.assign(Vue.prototype, VueGlobals);

(window as any).V = Vue;

const vm = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
